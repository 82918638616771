import React from "react";

import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import styled from "styled-components";

const Kontaktdaten = styled.p`
  padding-left: 10px;
`;

const Datenschutz = ({ location }) => (
  <Layout location={location}>
    <SEO title="Datenschutz" description="Hinweise zum Datenschutz" />
    <article>
      <h1>Hinweise zum Datenschutz</h1>
      <p>
        <strong>Genderhinweis</strong>: Aus Gründen der besseren Lesbarkeit wird
        auf eine geschlechtsneutrale Differenzierung verzichtet. Entsprechende
        Begriffe gelten im Sinne der Gleichbehandlung grundsätzlich für alle
        Geschlechter.
      </p>
      <h2>Allgemeines</h2>
      <p>
        Hiermit informiert der kombinat kickers mannheim (kkm) über die durch
        ihn stattfindende Erhebung, Verarbeitung und Speicherung
        personenbezogener Daten und die den betroffenen Personen nach dem
        Datenschutzrecht zustehenden Rechte.
      </p>
      <h2>Verantwortlicher für die Datenverarbeitung</h2>
      <Kontaktdaten>
        Theresa Rumford
        <br />
        Gießenstr. 20
        <br />
        68199 Mannheim
        <br />
        <a href="mailto:kombinat.kickers.mannheim@gmail.com">
          kombinat.kickers.mannheim@gmail.com
        </a>
      </Kontaktdaten>
      <h2>Rechtsgrundlage der Verarbeitung</h2>
      <p>
        Die personenbezogenen Daten betroffener Personen werden nur mit deren
        Einwilligung erhoben, verarbeitet und gespeichert.
      </p>
      <h2>
        Zweck der Verarbeitung personenbezogener Daten; Dauer der Speicherung
        personenbezogener Daten
      </h2>
      <p>
        <ol>
          <li>
            Zur Verwirklichung des Vereinszwecks werden folgende
            personenbezogenen Daten von den Vereinsmitgliedern erhoben,
            verarbeitet und/oder gespeichert:
            {/* grid with 2 columns */}
            <div class="gdpr-grid">
              {/* HEADER */}
              <div>Datenkategorie</div>
              <div>Verwendungszweck</div>
              {/* CONTENT ROW 1 */}
              <div>Identitäts- und Kontaktdaten</div>
              <div>
                Mitgliederpflege, Kommunikation, Durchführung des Spielbetriebs
              </div>
            </div>
          </li>
          <li>
            Personenbezogene Daten betroffener Personen gem. Abs. 1 werden
            solange vorgehalten, wie es für die Erfüllung der genannten Zwecke
            notwendig ist.
          </li>
          <li>
            Zur Verwirklichung des Vereinszwecks werden folgende
            personenbezogenen Daten von Personen, die keine Mitglieder des
            Vereins sind, erhoben, verarbeitet und/oder gespeichert:
            {/* grid with 2 columns */}
            <div class="gdpr-grid">
              {/* HEADER */}
              <div>Datenkategorie</div>
              <div>Verwendungszweck</div>
              {/* CONTENT ROW 1 */}
              <div>Identitäts- und Kontaktdaten</div>
              <div>Kommunikation</div>
            </div>
          </li>
          <li>
            Personenbezogene Daten betroffener Personen gem. Abs. 3 werden
            solange vorgehalten, wie es für die Verwirklichung der
            Vereinssatzung notwendig ist.
          </li>
        </ol>
      </p>
      <h2>Empfänger personenbezogener Daten</h2>
      <p>
        Die personenbezogenen Daten betroffener Personen werden, wenn diese am
        Spielbetrieb des Tischfußballverbandes Baden-Württemberg e.V. (TFVBW
        e.V.) teilnehmen oder teilnehmen möchten, an den TFVBW e.V.
        weitergeleitet. Die Mitgliedschaft des kkm im TFVBW e.V. erfordert zur
        Berechnung des Mitgliedsbeitrages des kkm die Namen, Geburtsdaten,
        Geschlechter, Vereinszugehörigkeiten und Spielernummern der betroffenen
        Person. Hat die betroffene Person dem kkm ein Spielerfoto zur Verfügung
        gestellt, wird dieses zur Repräsentation der betroffenen Person
        ebenfalls an den TFVBW e.V. weitergeleitet.
      </p>
      <p>
        Beabsichtigt der Verein, personenbezogene Daten betroffener Personen an
        andere als die hier explizit genannten Empfänger zu versenden, holt er
        sich die explizite Einwilligung der betroffenen Personen für diese
        bestimmten Zwecke ein.
      </p>
      <h2>Veröffentlichung personenbezogener Daten</h2>
      <p>
        Der kkm veröffentlicht im Zusammenhang mit dem Vereinszweck sowie
        satzungsgemäßen Veranstaltungen personenbezogene Daten und Fotos
        betroffener Personen auf der Homepage des Vereins.
      </p>
      <h2>
        Betroffenenrechte; mögliche Folgen der Löschung personenbezogener Daten
      </h2>
      <p>
        Jede natürliche Person hat ein Auskunftsrecht darüber, ob der kkm
        betreffende personenbezogene Daten speichert oder verarbeitet. Ist dies
        der Fall, so hat sie das Recht, Auskunft über die zu Ihrer Person
        gespeicherten Daten zu verlangen. Jede betroffene Person kann die
        Berichtigung oder die Löschung Ihrer Daten verlangen. Weiterhin kann ihr
        ein Recht auf Einschränkung der Verarbeitung der personenbezogenen Daten
        sowie ein Recht auf Herausgabe der bereitgestellten Daten in einem
        strukturierten, gängigen und maschinenlesbaren Format zustehen.
      </p>
      <p>
        Jede betroffene Person hat die Möglichkeit, sich mit einer Beschwerde an
        den oben genannten Verantwortlichen oder an die für den kkm zuständige
        Datenschutzaufsichtsbehörde zu wenden:
      </p>
      <Kontaktdaten>
        Der Landesbeauftragte für den Datenschutz und die Informationsfreiheit
        <br />
        Postfach 10 29 32
        <br />
        70025 Stuttgart
        <br />
        Telefon: 0711/61 55 41 0
        <br />
        Telefax: 0711/61 55 41 15
        <br />
        E-Mail:{" "}
        <a href="mailto:poststelle@lfdi.bwl.de">poststelle@lfdi.bwl.de</a>
      </Kontaktdaten>
      <p>
        Wurde die Verarbeitung der personenbezogenen Daten eingeschränkt, so
        dürfen diese - von ihrer Speicherung abgesehen - nur mit Einwilligung
        der betroffenen Person oder zur Geltendmachung, Ausübung oder
        Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer
        anderen natürlichen oder juristischen Person oder aus Gründen eines
        wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats
        verarbeitet werden. Dies hat den Verlust der Teilnahmeberechtigung am
        Spielbetrieb des TFVBW e.V. zur Folge.
      </p>
      <p>
        Verlangt die betroffene Person die Löschung ihrer personenbezogenen
        Daten, hat dies den Verlust der Mitgliedschaft im kkm sowie den Verlust
        der Teilnahmeberechtigung am Spielbetrieb des TFVBW e.V. zur Folge.
        Darüber hinaus werden im Einflussbereich des kkm veröffentlichte
        personenbezogene Daten der betroffenen Person entfernt. Weiterhin hat
        dies zur Folge, dass eine betroffene Person im Falle der erneuten
        Aufnahme in den kkm ihre personenbezogenen Daten erneut bereitstellen
        muss.
      </p>
      <h2>
        Freiwilligkeit der betroffenen Person, personenbezogene Daten
        bereitzustellen; mögliche Folgen der Nichtbereitstellung
      </h2>
      <p>Die Bereitstellung personenbezogener Daten ist freiwillig.</p>
      <p>
        Eine Nichtbereitstellung der personenbezogenen Daten oder ein
        Widerspruch zur oder ein Widerruf der Einwilligung zur Erhebung,
        Verarbeitung und Speicherung personenbezogener Daten der betroffenen
        Person hat zur Folge, dass die betroffene Person aus dem Verein
        ausgeschlossen wird oder ihr die Aufnahme in diesen verwehrt wird.
        Darüber hinaus ist eine Teilnahme am Spielbetrieb des TFVBW e.V. nicht
        möglich.
      </p>
      <p>
        Vor einer Bereitstellung personenbezogener Daten durch die betroffene
        Person kann sich die betroffene Person an den oben genannten
        Verantwortlichen wenden. Der Verantwortliche klärt den Betroffenen
        einzelfallbezogen darüber auf, ob die Bereitstellung personenbezogener
        Daten erforderlich ist, und welche Folgen die Nichtbereitstellung der
        personenbezogenen Daten hat.
      </p>
      <h2>Aktualisierung der Datenschutzhinweise</h2>
      <p>
        Diese Datenschutzhinweise können aufgrund von Änderungen, z.B. der
        gesetzlichen Bestimmungen, zu einem späteren Zeitpunkt angepasst werden.
        Eine jeweils aktuelle Fassung dieser Datenschutzhinweise ist unter{" "}
        <Link to={location.pathname}>{location.href}</Link> einsehbar.
      </p>
    </article>
  </Layout>
);

export default Datenschutz;
